

@media (max-width: 576px) {
    .cointainer {
      width: 100%;
      text-align: center;
      font-size: 10px;
      
    }
    .Availablebed{
        margin-right: 5px;
    }
    .number{
        font-size: 8px;
    }
    .date{
        font-size: 8px;
    } 
}

@media (max-width: 576px){
    .facility{
        text-align: center;
        align-items: center;
    }
    .facility1{
        text-align: center;
        
    }
    .box1{
        text-align: center;

    }
    

}
@media(max-width: 576px){
    .imagecontainer{
        text-align: center;
    }
    .image3{
     margin-left: 80px;
     padding-bottom: 30px;
    }
    .save{
        text-align: center;
        padding-bottom: 3px;
    }
}
@media(max-width: 576px){
    .Approvalsave{
        text-align: center;
        padding-bottom: 5px;

    }
    .Approvalsave1{
        font-size: 11px;
    }
    .Approval{
        font-size: 4px;
        padding-bottom: 5px;
        font-size: 2px;
    }
    .Approval1{
        font-size: smaller;
    }
}
@media(max-width: 576px){
    .Amount{
        text-align: center;
    }
}
@media(max-width: 576px){
    .Department{
        text-align: center;

    }
    .button1{
        font-size: 14px;
    }
}
@media(max-width: 576px){
    .Adddepartment{
        font-size: 10px;
        
    }
   
    .card .header{
        text-align: center;
    }
}
@media(max-width: 576px){
    .Adddoctor{
        text-align: center;
    }
    .Adddoctor1{
        margin-left:80px ;
        
    }
}
@media(max-width: 576px){
    .Adddoctorbutton{
        text-align: center;
    }
    .Adddoctorbutton1{
    font-size: 12px;
    }
    .Adddoctorbutton2{
        font-size: 12px;
        
    }
    .Save1{
        border-radius: 5px;
    }
}
@media(max-width: 576px){
    .AllDoctorcontainer{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;  
        font-size: 40px;
    }
    .card2{
        font-size: 15px;
        max-width: 400px;
        max-height: 200;
    }
    .member-thumb img {
        max-height: 60px;
        margin-top: 5px;
        padding-bottom: 0px;
    }
    .doctorname{
        padding-bottom: 0px;
    }
    .contentimg{
        font-size: 10px; 
        margin-bottom: 20px;
    } 
    .profilebutton{
        margin-bottom: 20px;
    }
    .doctordetails{
        padding-bottom: 0px;
    }
    .doctorcontaint{
        font-size: 20px;
    }
}
@media(max-width: 576px){
    .content{
     text-align: center;
    
    }
    .block-header{
        font-size: 14px;
        padding: 0px;

    }
}
@media(max-width){
    .Footer1{
        font-size: 10px;
        text-align: center;
    }
    .footer2{
        text-align: center;
    }  
}
@media(max-width: 576px){
   .facility{
       text-align: center;

   }
   .facility1{
       font-size: 8px;
   }
   .submit2{
       text-align: center;
       margin-left: 20px;
       
       text-justify: auto;
   }
   .response{
       font-size: 10px;
   }
  
    
}
@media(max-width: 576px){
    .tablepatient{
        font-size: '10px'
    

    }
}
@media(max-width: 576px){
    .Selectcharges{
        width: 100%;
    }
}

@media(max-width:576px){
    .searchbox{
        width: 50%;
    
    }
}
@media(max-width:576px){
    .clearfix{
        font-size: '15px'

    }

}
@media(max-width:576px){
    .Bedbilling{
        font-size:12px
    }

}
@media(max-width:576px){
   .Expansecontainer{
       font-size: 12px;
   }
   .clearfix{
       font-size: 12px;
   }
   .bar1{
       width:50%
   }
   .clearfix{
       font-size: 10px;

   }
   
   
}
@media(max-width:576px){
    .Logincontainer{
        font-size: 5px;
        
    }
    .form-control{
        width: 90%;
    }
}
@media(max-width:576px){
    .checkcontainer{
        text-align: center;
    }
}



@media(max-width:400px){
    .submitSaveButton{
        margin-left: 13.2rem  !important;
    }
    .submitUpdateButton{
        margin-left: 12rem !important;
    }
}
  